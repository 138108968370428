<template>
  <div class="bbox-list codebox-list">
    <van-empty v-if="!lists || lists.length < 1" description="暂无记录" />
    <LuckBag v-for="item in lists" :key="item.bid" :item="item" mod="agent" />
  </div>
  <!--/欧气福袋列表-->
</template>

<script>
import LuckBag from "@/components/LuckBag.vue";
export default {
  name: "Statistic",
  data() {
    return {
      lists: null,
    };
  },
  components: {
    LuckBag,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$ajax.post("/agent/box/list/", {}, "loading").then((res) => {
        this.lists = res.data;
      });
    },
    delbox(item) {
      this.$dialog
        .confirm({
          message: "确定要删除这个密码盲盒吗？",
        })
        .then(() => {
          this.$ajax
            .post("/agent/box/remove/", { bid: item.bid }, "loading")
            .then(() => {
              for (let i = this.lists.length - 1; i >= 0; i--) {
                if (item.bid === this.lists[i].bid) {
                  this.lists.splice(i, 1);
                }
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>
